/* Fonts */

@font-face {
    font-family: 'AktivGrotesk-Thin';
    src: url("/public/fonts/AktivGrotesk-Thin.ttf") format('truetype');
}

/* Tags */

* {
    margin: 0;
}

body {
    background-color: black;
    font-family: AktivGrotesk-Thin, sans-serif;
}

a {
    text-decoration: none;
    color: white;
}

/* Common */

.center-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

/* Navbar */

.navbar {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: black;
}

.navbar-logo {
    width: 20%;
    padding-left: 10%;
}

.navbar-logo img {
    width: 100px;
    height: 100px;
    rotate: -90deg;
    cursor: pointer;
}

.navbar-links {
    width: 80%;
    padding-right: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
}

.navbar-links a {
    margin-left: 5%;
    transition: color 0.3s ease;
}

.navbar-links a:hover {
    color: darkgray;
}

/* Homepage */

.homepage-project {
    width: 100%;
    height: 1000px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.homepage-project::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0);
    transition: background 0.3s ease;
    z-index: 1;
}

.homepage-project:hover::before {
    background: rgba(38, 38, 38, 0.8);
}

.homepage-project-name,
.homepage-project-year {
    color: white;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.homepage-project-name {
    font-size: 30px;
}

.homepage-project-year {
    font-size: 13px;
}

.homepage-project:hover .homepage-project-name,
.homepage-project:hover .homepage-project-year {
    opacity: 1;
    visibility: visible;
}

.spacer {
    width: 100%;
    height: 5px;
    background-color: white;
}

.navigation-helper {
    width: 60px;
    height: 60px;
    border-radius: 40px;
    background-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    z-index: 3;
    right: 40px;
    bottom: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.navigation-helper img {
    width: 30px;
    height: 30px;
    margin: auto;
}

/* Flow */

.presentation-flow {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.presentation-flow img {
    height: auto;
}

.down-arrow {
    margin-top: 5%;
    width: 50px;
    cursor: pointer;
}

.text-flow {
    margin-top: 15%;
    color: white;
    margin-left: 25%;
    margin-right: 25%;
    text-align: center;
    line-height: 25px;
}

.text-flow p:nth-child(1) {
    font-size: 18px;
}

.text-flow p:nth-child(2) {
    margin-top: 10%;
    font-size: 14px;
    color: #939191;
}

.flow-carousel {
    width: 100%;
    height: auto;
}

video {
    width: 100%;
}